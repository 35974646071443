import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useGeolocated } from "react-geolocated";
import { BiCurrentLocation } from "react-icons/bi";
import InputField from "../components/inputField";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AnimatedImageCard from "../components/animated-image-card";
import ContactFormHome from "../components/contact-form-home";
import CartContext from "../context/CartContext";
import ContactFormMorePeople from "../components/contact-form-more-people";
import VerticalListingsPropertyCard from "../components/vertical-listings-property-card";
import ExpertCard from "../components/expert-card";

mapboxgl.workerClass =
	require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const PopularLocationPage = ({
	data: {
		popLocation,
		heroInterior,
		paddington,
		canaryWharf,
		piccadilly,
		westminster,
		site,
		bottomImage1,
	},
	pageContext: { properties },
}) => {
	const { settingVal, onLocationChange } = React.useContext(CartContext);

	const siteUrl = site.siteMetadata.siteUrl;

	const [showForm, setShowForm] = useState(false);
	const [mapChecked, setMapChecked] = useState(false);
	const [mapMobileChecked, setMapMobileChecked] = useState(false);
	const [loading, setLoading] = useState(true);
	const [shortOffices, setShortOffices] = useState(properties);
	const [shortOfficesNew, setShortOfficesNew] = useState([]);

	const defaultImage = bottomImage1;

	// Check if a featured image is available and set it initially
	const initialImage = popLocation.popularLocationsFields.featuredImage
		? popLocation.popularLocationsFields.featuredImage.localFile
		: defaultImage;

	const [bottomImage, setBottomImage] = useState(initialImage);
	useEffect(() => {
		const temp = [...properties];
		setShortOfficesNew(temp.slice());
	}, []);

	useEffect(() => {
		if (typeof Window !== "undefined") {
			onLocationChange(
				popLocation.popularLocationsFields.latitude,
				popLocation.popularLocationsFields.longitude
			);
			settingVal(
				popLocation.popularLocationsFields.locationName,
				"locationValue"
			);
			settingVal("Office Type", "selectedOffice");
			settingVal({ min: 0, max: 75, reset: true }, "numberOfPeople");
			settingVal(
				{
					min: 0,
					max: 150000,
					minPerDesk: 0,
					maxPerDesk: 2000,
					perDesk: false,
				},
				"budgetPerDesk"
			);
			settingVal("Sort", "sortedBy");
			settingVal(10, "distanceAmount");
			settingVal([], "selectedFeatures");

			// Set the filtered properties passed from pageContext

			settingVal(properties, "shortOffices");
		}
	}, []);

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: `${popLocation.seoFields.metaTitle}`,
				item: {
					url: `${siteUrl}/${popLocation.popularLocationsFields.url}`,
					id: `${siteUrl}/${popLocation.popularLocationsFields.url}`,
				},
			},
		],
	};

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: true,
			},
			isOptimisticGeolocationEnabled: false,
			userDecisionTimeout: 5000,
		});

	const OnLocationChangeGeo = () => {
		if (isGeolocationAvailable && isGeolocationEnabled && coords) {
			onLocationChange(coords.latitude, coords.longitude);
			settingVal("Your location", "locationValue");
		} else {
			alert("Please allow access to your location on your browser");
		}
	};

	return (
		<>
			<Helmet>
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={popLocation.seoFields?.metaTitle}
				description={popLocation.seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/${popLocation.popularLocationsFields.url}`,
					title: `${popLocation.seoFields?.opengraphTitle}`,
					description: `${popLocation.seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${popLocation.seoFields?.image?.localFile.publicURL}`,
							width: `${popLocation.seoFields?.image?.localFile.childImageSharp.original.width}`,
							height: `${popLocation.seoFields?.image?.localFile.childImageSharp.original.height}`,
							alt: `${popLocation.seoFields?.image?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<Container>
						<Row className="align-items-center">
							<Col xs={10} sm={11} xl={6}>
								<div className="pb-1 d-flex align-items-center">
									<InputField staticPage propertiesPage />
								</div>
							</Col>
							<Col className="pb-2 d-xl-none" xs={2} sm={1}>
								<Link
									className="align-self-center"
									style={{ cursor: "pointer" }}
									onClick={OnLocationChangeGeo}
									to="/properties"
								>
									<BiCurrentLocation className="fs-3 card-link" />
								</Link>
							</Col>
							<Col className="text-end d-none d-xl-block" lg={6}>
								<Button
									style={{ borderRadius: "200px", fontWeight: "700" }}
									as={Link}
									onClick={() => settingVal(1, "distanceAmount")}
									to="/properties"
									variant="primary"
									className="w-100 w-md-auto px-4 text-white"
								>
									Show map
								</Button>
							</Col>
						</Row>
					</Container>
					<hr className="text-med-grey py-0 my-0" />
					<Container className="d-none d-xl-block">
						<Row>
							<Col className="text-center pt-4">
								<Button
									style={{ borderRadius: "200px", fontWeight: "700" }}
									as={Link}
									onClick={() => settingVal(1, "distanceAmount")}
									to="/properties"
									variant="outline-primary"
									className="w-100 w-md-auto px-4"
								>
									REFINE YOUR SEARCH
								</Button>
							</Col>
						</Row>
					</Container>
					<section className="pt-4 pb-5">
						<Container>
							<Row className="justify-content-center d-xl-none align-items-center">
								<Col xs={12} className="text-center">
									<Button
										style={{ borderRadius: "200px", fontWeight: "700" }}
										as={Link}
										onClick={() => settingVal(1, "distanceAmount")}
										to="/properties"
										variant="outline-primary"
										className="w-100 w-md-auto px-4"
									>
										REFINE YOUR SEARCH
									</Button>
								</Col>
							</Row>

							{shortOfficesNew.length < 1 && (
								<div>
									<Row className="h-100 d-none d-xl-flex">
										<Col>
											<Row
												className="position-relative mt-0 py-0 mb-0"
												style={{ index: 1 }}
											>
												{shortOffices?.map((property, i) => (
													<Col className="mb-5" xs={12} md={6} xl={4}>
														<VerticalListingsPropertyCard
															property={property}
															index={i}
														/>
													</Col>
												))}
											</Row>
										</Col>
									</Row>

									<Row className="h-100 d-xl-none mt-4">
										<Col>
											<Row
												className="position-relative mt-0 py-0 mb-0"
												style={{ index: 1 }}
											>
												{shortOffices?.map((property, i) => (
													<Col className="mb-5" xs={12} md={6} lg={6}>
														<VerticalListingsPropertyCard
															property={property}
															index={i}
														/>
													</Col>
												))}
											</Row>
										</Col>
									</Row>
								</div>
							)}
							{shortOfficesNew.length > 1 && (
								<div>
									<Row className="h-100 d-none d-xl-flex">
										<Col>
											<Row
												className="position-relative mt-0 py-0 mb-0"
												style={{ index: 1 }}
											>
												{shortOfficesNew?.map((property, i) => (
													<Col className="mb-5" xs={12} md={6} xl={4}>
														<VerticalListingsPropertyCard
															property={property}
															index={i}
														/>
													</Col>
												))}
											</Row>
										</Col>
									</Row>

									<Row className="h-100 d-xl-none mt-4">
										<Col>
											<Row
												className="position-relative mt-0 py-0 mb-0"
												style={{ index: 1 }}
											>
												{shortOfficesNew?.map((property, i) => (
													<Col className="mb-5" xs={12} md={6} lg={6}>
														<VerticalListingsPropertyCard
															property={property}
															index={i}
														/>
													</Col>
												))}
											</Row>
										</Col>
									</Row>
								</div>
							)}

							<Row>
								<Col lg={mapChecked === true ? 6 : 12} className="text-center">
									<Button
										style={{ borderRadius: "200px" }}
										as={Link}
										onClick={() => settingVal(1, "distanceAmount")}
										to="/properties"
										variant="dark-blue w-100 w-md-auto px-4"
									>
										REFINE YOUR SEARCH
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5">
						<Container>
							<Row className="g-5 align-items-center">
								<Col lg={4}>
									<div style={{ borderRadius: "10px", overflow: "hidden" }}>
										<GatsbyImage
											image={bottomImage.childImageSharp.gatsbyImageData}
											className="w-100"
											alt={`Office space in ${popLocation.title}`}
										/>
									</div>
								</Col>
								<Col lg={8}>
									<h1>
										Office space in{" "}
										{popLocation.popularLocationsFields.locationName}
									</h1>
									{parse(popLocation.popularLocationsFields.description)}
								</Col>
							</Row>
						</Container>
					</section>
					<section
						className="bg-white position-relative mt-4"
						style={{ zIndex: 1 }}
					>
						<Container className="bg-white my-lg-6 my-3 my-md-5">
							<Row className="g-4">
								<Col lg={6}>
									<div
										style={{
											boxShadow: "0px 1px 20px #00000029",
											borderRadius: "12px",
										}}
										className="py-3 py-md-5 px-3 px-md-5 mt-2 mb-3 bg-white"
									>
										<Row>
											<Col>
												<h2 className="mb-3 text-start">Get in touch</h2>
											</Col>
											<Row>
												<Col>
													<p className="mb-5 text-start">
														Fill in your contact details below and we'll put you
														in touch with one of our representatives
													</p>
												</Col>
											</Row>
										</Row>
										<ContactFormHome />
									</div>
									<ExpertCard />
								</Col>
								<Col lg={6} className="ps-lg-5">
									<Row className="pt-3 pt-lg-6 pb-3 pb-lg-5">
										<Col className="text-center">
											<h2>Popular Locations</h2>
										</Col>
									</Row>
									<Row className="g-3 pb-5">
										<Col xs={6}>
											<AnimatedImageCard imageData={paddington} />
										</Col>
										<Col xs={6}>
											<AnimatedImageCard imageData={canaryWharf} />
										</Col>
										<Col xs={6}>
											<AnimatedImageCard imageData={piccadilly} />
										</Col>
										<Col xs={6}>
											<AnimatedImageCard imageData={westminster} />
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default PopularLocationPage;

export const pageQuery = graphql`
	query PopularLocationById($id: String!) {
		popLocation: wpPopularLocation(id: { eq: $id }) {
			seoFields {
				metaTitle
				metaDescription
				opengraphDescription
				opengraphTitle
				productSchema
				image {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
						}
						publicURL
					}
				}
			}
			title
			popularLocationsFields {
				featuredImage {
					altText
					localFile {
						publicURL
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 70
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				description
				latitude
				longitude
				locationName
				metaDescription
				property1
				property2
				property3
				property4
				property5
				url
			}
		}
		heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
			altText
			localFile {
				publicURL
				childImageSharp {
					original {
						height
						width
					}
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 70
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		paddington: wpMediaItem(title: { eq: "Bank" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		bottomImage1: file(relativePath: { eq: "Bank-Static-Image.jpg" }) {
			childImageSharp {
				original {
					height
					width
				}
				gatsbyImageData(
					formats: [AUTO, WEBP]
					quality: 70
					transformOptions: { cropFocus: CENTER, fit: CONTAIN }
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
		}

		canaryWharf: wpMediaItem(title: { eq: "London Bridge" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		piccadilly: wpMediaItem(title: { eq: "Mayfair" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}

		westminster: wpMediaItem(title: { eq: "Shoreditch-Static-Page-square" }) {
			altText
			localFile {
				childImageSharp {
					gatsbyImageData(
						formats: [AUTO, WEBP]
						quality: 30
						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
						layout: CONSTRAINED
						placeholder: BLURRED
					)
				}
			}
		}
		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
